import { createSlice } from '@reduxjs/toolkit';



export const onlineSlice = createSlice({
    name: 'online',
    initialState: {
        online: null,
    },
    reducers: {
        online: (state, action) => {
            state.online = action.payload;
        },
    }
});

export const { online } = onlineSlice.actions;

export const selectonline = (state) => state.online.online;

export default onlineSlice.reducer;
