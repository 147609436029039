import React from 'react'
import axios from 'axios'

function Mobsrch({ data, setflip }) {

  axios.defaults.withCredentials = true

  const addfriend = async () => {
    const arr = {
      image: data?.image,
      combined_id: data?.combined_id,
      name: data?.name,
      email: data?.email,
      username: data?.username,
      eth: data?.eth_address,
      btc: data?.btc_address,
      notification: data?.notification,
    }

    var jsonString = JSON.stringify(arr);

    localStorage.setItem("myObject", jsonString);


    const value = {
      combined_id: data?.combined_id
    }

    try {
      await axios.post('https://wikiescrow.org/escrow/includes/clearMessageAlert.inc.php', value).then((response) => {
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setflip(false)

  }

  return (
    <>
      <div key={data.username} onClick={addfriend} className='sidebars'>
        <div className='avatarbod'>
          <div className={`${data?.image}`}></div>
        </div>

        {/* <div className='userStatusWrapper'>{timer ? <div className='userOnlineStatus'></div> : <div className='userOfflineStatus'></div>}</div> */}
        <div className='sidebarwrt' >
          <h3>{data?.name}</h3>
          <p>@{data?.username}</p>
        </div>
      </div>

    </>
  )
}

export default Mobsrch