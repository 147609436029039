import { createSlice } from '@reduxjs/toolkit';



export const notifySlice = createSlice({
    name: 'notify',
    initialState: {
        notify: null,
    },
    reducers: {
        notify: (state, action) => {
            state.notify = action.payload;
        },
    }
});

export const { notify } = notifySlice.actions;

export const selectnotify = (state) => state.notify.notify;

export default notifySlice.reducer;
