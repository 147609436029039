import Lottie from 'lottie-react'
import React from 'react'
import { Detector } from 'react-detect-offline'
import lott from '../asset/Animation - 1710972521237.json'

function Connection(props) {
    return (
        <div>
            <Detector
                render={({ online }) => (
                    online ?
                        props.children
                        :
                        <div className='lot'>
                            <Lottie className='ldd smlld' animationData={lott} /> connecting...
                        </div>
                )}
            />
        </div>
    )
}

export default Connection