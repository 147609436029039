import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTimeAgo from 'react-time-ago'
import Web3 from "web3";
import { selectinfo } from '../features/infoslice'
import { toast } from 'react-toastify';
import axios from 'axios'


function Messages({ msg, scrollToBottom, mesg, setmsgrl, setmsgr2, setmsgr3, setmsgr4, setmsgr5, frnd, }) {

    axios.defaults.withCredentials = true

    const user = useSelector(selectinfo)
    const [frnd11, setfrnd11] = useState()

    var storedJsonString = localStorage.getItem("myObject");

    const func1 = () => {
        setfrnd11(JSON.parse(storedJsonString))
    }

    useEffect(() => {
        func1()
    }, [])



    const gmtp = mesg?.game_type
    const gmno = mesg?.game_number


    const ref = useRef()

    useEffect(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
        scrollToBottom()
    }, [])


    // ------------------- //
    // ----accept btn function--- //
    // ------------------- //







    const accept = async (e) => {
        e.preventDefault()

        const em = frnd?.email
        const us = frnd?.username
        const eth = frnd?.eth_address
        const btc = frnd?.btc_address
        const im = frnd?.image
        const nm = frnd?.name

        //loader start
        setmsgrl(true)
        scrollToBottom()

        //Balance check
        //(userr?.balance >= gmno)

        const currentTimestamp = new Date()

        var minAmountCheck = true;

        if (gmtp === "BTC") {
            if (gmno >= 0.001) {
                minAmountCheck = false;
            } else {
                toast.error('Amount must be greater than or equal to 0.001 BTC.');
                setmsgrl(false);
                minAmountCheck = true;
            }
        } else if (gmtp === "ETH") {
            if (gmno >= 0.01) {
                minAmountCheck = false;
            } else {
                toast.error('Amount must be greater than or equal to 0.01 ETH.');
                setmsgrl(false);
                minAmountCheck = true;
            }
        } else if (gmtp === "USDT") {
            if (gmno >= 50) {
                minAmountCheck = false;
            } else {
                toast.error('Amount must be greater than or equal to 50 USDT.');
                setmsgrl(false);
                minAmountCheck = true;
            }
        }

        if (minAmountCheck === false) {
            if (frnd?.usertype === "super") {


                const last = msg[msg.length - 1]

                try {

                    const values = {
                        frnd_id: frnd?.username,
                        combined_id: frnd?.combined_id,
                        user_id: user?.user.username,
                        gametype: last.game_type,
                        gamenumber: last.game_number,
                        serial: last.serial,
                        req: 'accept',
                        activechat: 'hold',
                        updated_message_type: 'invited',
                    }

                    await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                        if (response?.status === 200) {

                            toast.info('Please check your email for your escrow receipt.');
                            setTimeout(() => {
                                setmsgrl(false)
                            }, 1000);
                            clearTimeout(setmsgrl(true))
                            const value = {
                                gt: last.game_type,
                                gn: last.game_number,
                                em: em,
                                us: us,
                                nm: nm,
                                im: im,
                                btc: btc,
                                eth: eth
                            }

                            await axios.post('https://wikiescrow.org/escrow/mail/accept.php', value).then((response) => {
                                ;
                            })
                            await axios.post('https://wikiescrow.org/escrow/mail/receipt.php', value).then((response) => {
                                ;
                            })

                            scrollToBottom()
                        }
                    })

                } catch (e) {

                }

            } else if (frnd?.usertype !== "super") {

                if (gmtp === "BTC") {

                    const userBTCWalletAddress = frnd?.btc;


                    async function fetchBTCBalance() {
                        async function fetchData() {
                            const btcApiUrl = `https://blockstream.info/api/address/${userBTCWalletAddress}`;

                            try {

                                const response = await fetch(btcApiUrl);
                                const data = await response.json();

                                const final_balance = data.chain_stats.funded_txo_sum - data.chain_stats.spent_txo_sum;
                                const btcbalance = Number(final_balance) / 100000000; // Convert from satoshis to BTC

                                if (btcbalance >= gmno) {


                                    const last = msg[msg.length - 1]

                                    try {

                                        const values = {
                                            frnd_id: frnd?.username,
                                            combined_id: frnd?.combined_id,
                                            user_id: user?.user.username,
                                            gametype: last.game_type,
                                            gamenumber: last.game_number,
                                            serial: last.serial,
                                            req: 'accept',
                                            activechat: 'hold',
                                            updated_message_type: 'invited',
                                        }

                                        await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                                            if (response?.status === 200) {

                                                toast.info('Please check your email for your escrow receipt.');

                                                setTimeout(() => {
                                                    setmsgrl(false)
                                                }, 1000);
                                                clearTimeout(setmsgrl(true))

                                                const value = {
                                                    gt: last.game_type,
                                                    gn: last.game_number,
                                                    em: em,
                                                    us: us,
                                                    nm: nm,
                                                    im: im,
                                                    btc: btc,
                                                    eth: eth
                                                }

                                                await axios.post('https://wikiescrow.org/escrow/mail/accept.php', value).then((response) => {
                                                    ;
                                                })
                                                await axios.post('https://wikiescrow.org/escrow/mail/receipt.php', value).then((response) => {
                                                    ;
                                                })
                                                scrollToBottom()

                                            }
                                        })

                                    } catch (e) {

                                    }



                                } else {

                                    const last = msg[msg.length - 1]
                                    try {

                                        const values = {
                                            frnd_id: frnd?.username,
                                            combined_id: frnd?.combined_id,
                                            user_id: user?.user.username,
                                            gametype: last.game_type,
                                            gamenumber: last.game_number,
                                            serial: last.serial,
                                            req: 'accept',
                                            activechat: 'start',
                                            updated_message_type: 'insuf',
                                        }

                                        await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then((response) => {
                                            if (response?.status === 200) {


                                                // Use setTimeout with the named function



                                                scrollToBottom();

                                                setTimeout(() => {
                                                    setmsgrl(false)
                                                }, 1000);

                                                clearTimeout(setmsgrl(true))
                                                return () => {
                                                    // Clean up: remove the callback function
                                                }

                                            }
                                        })

                                    } catch (e) {

                                    }
                                }

                            } catch (error) {
                                console.error('Error:', error);
                            }
                        }

                        fetchData();

                    }
                    fetchBTCBalance();

                } else if (gmtp === "ETH") {

                    const userETHWalletAddress = frnd?.eth;

                    async function fetchBalance() {
                        if (!userETHWalletAddress) return;

                        try {
                            const web3 = new Web3(process.env.REACT_APP_API_KEY1);

                            const balance = await web3.eth.getBalance(userETHWalletAddress);

                            const balanceInEther = web3.utils.fromWei(balance, "ether");



                            if (Number(balanceInEther) >= gmno) {

                                const last = msg[msg.length - 1]

                                try {

                                    const values = {
                                        frnd_id: frnd?.username,
                                        combined_id: frnd?.combined_id,
                                        user_id: user?.user.username,
                                        gametype: last.game_type,
                                        gamenumber: last.game_number,
                                        serial: last.serial,
                                        req: 'accept',
                                        activechat: 'hold',
                                        updated_message_type: 'invited',
                                    }

                                    await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                                        if (response?.status === 200) {

                                            toast('Please check your email for your escrow receipt.');
                                            setTimeout(() => {
                                                setmsgrl(false)
                                            }, 1000);
                                            clearTimeout(setmsgrl(true))

                                            const value = {
                                                gt: last.game_type,
                                                gn: last.game_number,
                                                em: em,
                                                us: us,
                                                nm: nm,
                                                im: im,
                                                btc: btc,
                                                eth: eth
                                            }

                                            await axios.post('https://wikiescrow.org/escrow/mail/accept.php', value).then((response) => {
                                                ;
                                            })
                                            await axios.post('https://wikiescrow.org/escrow/mail/receipt.php', value).then((response) => {
                                                ;
                                            })

                                            scrollToBottom()
                                        }
                                    })

                                } catch (e) {
                                    console.log(e);
                                }



                            } else {
                                const last = msg[msg.length - 1]
                                try {

                                    const values = {
                                        frnd_id: frnd?.username,
                                        combined_id: frnd?.combined_id,
                                        user_id: user?.user.username,
                                        gametype: last.game_type,
                                        gamenumber: last.game_number,
                                        serial: last.serial,
                                        req: 'accept',
                                        activechat: 'start',
                                        updated_message_type: 'insuf',
                                    }

                                    await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then((response) => {
                                        if (response?.status === 200) {



                                            // Use setTimeout with the named function


                                            scrollToBottom();
                                            setTimeout(() => {
                                                setmsgrl(false)
                                            }, 1000);

                                            clearTimeout(setmsgrl(true))
                                            return () => {
                                                // Clean up: remove the callback function
                                            }
                                        }
                                    })

                                } catch (e) {

                                }
                            }

                        } catch (error) {
                            console.log(error);
                            toast.error('Network error, try again');
                            setmsgrl(false);

                            // Use setTimeout with the named function

                        }
                    }

                    fetchBalance();

                } else if (gmtp === "USDT") {
                    const tokenAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
                    const userETHWalletAddress = frnd?.eth;


                    async function fetchTokenBalance() {
                        if (!tokenAddress || !userETHWalletAddress) return;

                        try {
                            const web3 = new Web3(process.env.REACT_APP_API_KEY1);

                            const tokenABI = [
                                {
                                    "constant": true,
                                    "inputs": [],
                                    "name": "name",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "string"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_spender",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "approve",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [],
                                    "name": "totalSupply",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "uint256"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_from",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_to",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "transferFrom",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [],
                                    "name": "decimals",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "uint8"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_from",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "roast",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [],
                                    "name": "tether",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "address"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [
                                        {
                                            "name": "",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "balanceOf",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "uint256"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [
                                        {
                                            "name": "",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "frozenAcount",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_star",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "boltStar",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [],
                                    "name": "symbol",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "string"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_from",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "burn",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "mint",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_to",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "transfer",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_from",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "bolt",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_spender",
                                            "type": "address"
                                        },
                                        {
                                            "name": "_value",
                                            "type": "uint256"
                                        },
                                        {
                                            "name": "_extradata",
                                            "type": "bytes"
                                        }
                                    ],
                                    "name": "approveAndCall",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [
                                        {
                                            "name": "",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "superBolt",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "uint256"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": true,
                                    "inputs": [
                                        {
                                            "name": "",
                                            "type": "address"
                                        },
                                        {
                                            "name": "",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "allowance",
                                    "outputs": [
                                        {
                                            "name": "",
                                            "type": "uint256"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "view",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "_to",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "seal",
                                    "outputs": [
                                        {
                                            "name": "success",
                                            "type": "bool"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "constant": false,
                                    "inputs": [
                                        {
                                            "name": "target",
                                            "type": "address"
                                        },
                                        {
                                            "name": "freeze",
                                            "type": "bool"
                                        }
                                    ],
                                    "name": "freezeAccount",
                                    "outputs": [],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "function"
                                },
                                {
                                    "inputs": [
                                        {
                                            "name": "initialSupply",
                                            "type": "uint256"
                                        }
                                    ],
                                    "payable": false,
                                    "stateMutability": "nonpayable",
                                    "type": "constructor"
                                },
                                {
                                    "anonymous": false,
                                    "inputs": [
                                        {
                                            "indexed": true,
                                            "name": "from",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": true,
                                            "name": "to",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": false,
                                            "name": "value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "Transfer",
                                    "type": "event"
                                },
                                {
                                    "anonymous": false,
                                    "inputs": [
                                        {
                                            "indexed": true,
                                            "name": "_owner",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": true,
                                            "name": "_spender",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": false,
                                            "name": "_value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "Approval",
                                    "type": "event"
                                },
                                {
                                    "anonymous": false,
                                    "inputs": [
                                        {
                                            "indexed": true,
                                            "name": "from",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": false,
                                            "name": "value",
                                            "type": "uint256"
                                        }
                                    ],
                                    "name": "Burn",
                                    "type": "event"
                                },
                                {
                                    "anonymous": false,
                                    "inputs": [
                                        {
                                            "indexed": false,
                                            "name": "target",
                                            "type": "address"
                                        },
                                        {
                                            "indexed": false,
                                            "name": "frozen",
                                            "type": "bool"
                                        }
                                    ],
                                    "name": "FrozenFunds",
                                    "type": "event"
                                }
                            ];

                            const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

                            const tokenBalance = await tokenContract.methods.balanceOf(userETHWalletAddress).call();

                            const formattedBalance = Number(tokenBalance) / 10 ** 6;


                            if (formattedBalance >= gmno) {


                                const last = msg[msg.length - 1]

                                try {

                                    const values = {
                                        frnd_id: frnd?.username,
                                        combined_id: frnd?.combined_id,
                                        user_id: user?.user.username,
                                        gametype: last.game_type,
                                        gamenumber: last.game_number,
                                        serial: last.serial,
                                        req: 'accept',
                                        activechat: 'hold',
                                        updated_message_type: 'invited',
                                    }

                                    await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                                        if (response?.status === 200) {

                                            toast.info('Please check your email for your escrow receipt.');

                                            setTimeout(() => {
                                                setmsgrl(false)
                                            }, 1000);
                                            clearTimeout(setmsgrl(true))

                                            const value = {
                                                gt: last.game_type,
                                                gn: last.game_number,
                                                em: em,
                                                us: us,
                                                nm: nm,
                                                im: im,
                                                btc: btc,
                                                eth: eth
                                            }

                                            await axios.post('https://wikiescrow.org/escrow/mail/accept.php', value).then((response) => {
                                                ;
                                            })
                                            await axios.post('https://wikiescrow.org/escrow/mail/receipt.php', value).then((response) => {
                                                ;
                                            })

                                            scrollToBottom()

                                        }
                                    })

                                } catch (e) {

                                }


                            } else {




                                const last = msg[msg.length - 1]
                                try {

                                    const values = {
                                        frnd_id: frnd?.username,
                                        combined_id: frnd?.combined_id,
                                        user_id: user?.user.username,
                                        gametype: last.game_type,
                                        gamenumber: last.game_number,
                                        serial: last.serial,
                                        req: 'accept',
                                        activechat: 'start',
                                        updated_message_type: 'insuf',
                                    }

                                    await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then((response) => {
                                        if (response?.status === 200) {


                                            setmsgrl(false);

                                            // Use setTimeout with the named function


                                            scrollToBottom();

                                        }
                                    })

                                } catch (e) {

                                }
                            }

                        } catch (error) {
                            toast.error('Network error, try again');
                            setmsgrl(false);

                            // Use setTimeout with the named function

                        }
                    }

                    fetchTokenBalance();

                }

            }
        }

    }










    const decline = async (e) => {
        e.preventDefault()

        setmsgr2(true)
        scrollToBottom()

        const em = frnd?.email
        const us = frnd?.username

        const last = msg[msg.length - 1]

        try {

            const values = {
                frnd_id: frnd?.username,
                combined_id: frnd?.combined_id,
                user_id: user?.user.username,
                gametype: last.game_type,
                gamenumber: last.game_number,
                serial: last.serial,
                req: 'decline',
                activechat: 'start',
                updated_message_type: 'invited',
            }

            await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                if (response?.status === 200) {

                    setTimeout(() => {
                        setmsgr2(false)
                    }, 1000);
                    clearTimeout(setmsgr2(true))

                    const value = {
                        gt: last.game_type,
                        gn: last.game_number,
                        em: em,
                        us: us,
                    }

                    await axios.post('https://wikiescrow.org/escrow/mail/decline.php', value).then((response) => {
                        ;
                    })
                    scrollToBottom()
                }
            })

        } catch (e) {

        }



    }










    // ------------------------//
    // ---------end game for current user------//
    // ------------------------//


    const endbtn = async (e) => {
        e.preventDefault()

        setmsgr3(true)
        scrollToBottom()

        const last = msg[msg.length - 1]
        const em = frnd?.email
        const us = frnd?.username

        try {

            const values = {
                frnd_id: frnd?.username,
                combined_id: frnd?.combined_id,
                user_id: user?.user.username,
                gametype: last.game_type,
                gamenumber: last.game_number,
                serial: last.serial,
                req: 'end',
                activechat: 'start',
                updated_message_type: 'accepted',
            }

            await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                if (response?.status === 200) {
                    setTimeout(() => {
                        setmsgr3(false)
                    }, 1000);
                    clearTimeout(setmsgr3(true))

                    const value = {
                        gt: last.game_type,
                        gn: last.game_number,
                        em: em,
                        us: us,
                    }

                    await axios.post('https://wikiescrow.org/escrow/mail/end.php', value).then((response) => {
                        ;
                    })

                    scrollToBottom()
                }
            })

        } catch (e) {

        }


    }










    // ------------------------//
    // ---------end game for other user------//
    // ------------------------//

    const endbtn2 = async (e) => {
        e.preventDefault()

        setmsgr4(true)
        scrollToBottom()

        const last = msg[msg.length - 1]
        const em = frnd?.email
        const us = frnd?.username

        try {

            const values = {
                frnd_id: frnd?.username,
                combined_id: frnd?.combined_id,
                user_id: user?.user.username,
                gametype: last.game_type,
                gamenumber: last.game_number,
                serial: last.serial,
                req: 'ended',
                activechat: 'start',
                updated_message_type: 'accepteed',
            }

            await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                if (response?.status === 200) {
                    setTimeout(() => {
                        setmsgr4(false)
                    }, 1000);
                    clearTimeout(setmsgr4(true))

                    const value = {
                        gt: last.game_type,
                        gn: last.game_number,
                        em: em,
                        us: us,
                    }

                    await axios.post('https://wikiescrow.org/escrow/mail/end.php', value).then((response) => {
                        ;
                    })


                    scrollToBottom()
                }
            })

        } catch (e) {

        }

    }










    const cnclbtn = async (e) => {
        e.preventDefault()
        setmsgr5(true)
        const last = msg[msg.length - 1]
        const em = frnd?.email
        const us = frnd?.username

        try {

            const values = {
                frnd_id: frnd?.username,
                combined_id: frnd?.combined_id,
                user_id: user?.user.username,
                gametype: last.game_type,
                gamenumber: last.game_number,
                serial: last.serial,
                req: 'cancel',
                activechat: 'start',
                updated_message_type: 'invited',
            }

            await axios.post('https://wikiescrow.org/escrow/includes/editMessage.inc.php', values).then(async (response) => {

                if (response?.status === 200) {
                    setTimeout(() => {
                        setmsgr5(false)
                    }, 1000);
                    clearTimeout(setmsgr5(true))

                    const value = {
                        gt: last.game_type,
                        gn: last.game_number,
                        em: em,
                        us: us,
                    }

                    await axios.post('https://wikiescrow.org/escrow/mail/cancel.php', value).then((response) => {
                        ;
                    })


                    scrollToBottom()
                }
            })

        } catch (e) {

        }




    }




    const num = new Intl.NumberFormat('en-us', {
        maximumFractionDigits: 3
    })



    const timeInMillis = mesg?.timestamp * 1000;


    return (
        <div ref={ref}>
            {
                user?.user.username === mesg?.user_id ?
                    <>
                        {mesg?.message_type === 'invite' || mesg?.message_type === 'invited' ?
                            <div className='pmesssage'>
                                <div className='pmsg'>
                                    <div className='inner'>
                                        <p>You have successfully sent an escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} to <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                    </div>
                                    <div style={mesg?.message_type === 'invited' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>
                                        <div className='cnclbtn'>
                                            <button onClick={cnclbtn} type='submit' className='dec' >CANCEL</button>
                                        </div>

                                    </div>
                                    <small>
                                        <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                    </small>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    mesg?.message_type === 'accept' || mesg?.message_type === 'accepted' ?
                                        <>
                                            <div className='pmesssage'>
                                                <p className='sessionStatusTextTop'>Escrow started</p>
                                                <div className='pmsg'>
                                                    <div className='inner'>
                                                        <p>You accepted the escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} sent by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                    </div>

                                                    <small>
                                                        <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                    </small>
                                                </div>
                                            </div>

                                            <div className='pmesssage'>
                                                <div className='pmsg'>
                                                    <>
                                                        <div className='inner'>
                                                            <p>We have confirmed that the escrow amount of {num.format(mesg?.game_number)} {mesg?.game_type} is in the wallet ({mesg?.game_type === 'BTC' ? frnd?.btc : frnd?.eth}) provided by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                        </div>
                                                        <div style={mesg?.message_type === 'accepted' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>
                                                            <div className='cnclbtn' onClick={endbtn}>
                                                                <button type='submit' className='dec' >End</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    { }

                                                    <small>
                                                        <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                    </small>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                            {
                                                mesg?.message_type === 'null' || mesg?.message_type === 'accepteed' ?
                                                    <div className='pmesssage'>
                                                        <div className='pmsg'>
                                                            <div className='inner'>
                                                                <p>We have confirmed that the escrow amount of {num.format(mesg?.game_number)} {mesg?.game_type} is in the wallet ({mesg?.game_type === 'BTC' ? frnd?.btc : frnd?.eth}) provided by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                            </div>
                                                            <div style={mesg?.message_type === 'accepteed' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>
                                                                <button onClick={endbtn2} className='dec' >End</button>
                                                            </div>
                                                            <small>
                                                                <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                            </small>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            mesg?.message_type === 'end' ?
                                                                <div className='pmesssage'>
                                                                    <div className='pmsg'>
                                                                        <div className='inner'>
                                                                            <p>The escrow session initiated by <span className="bubbleUsername">{frnd?.username}</span> has been concluded by you.</p>
                                                                        </div>
                                                                        <small>
                                                                            <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                        </small>
                                                                    </div>
                                                                    <p className='sessionStatusTextBottom'>Escrow ended</p>
                                                                </div>
                                                                :
                                                                mesg?.message_type === 'ended' ?
                                                                    <div className='pmesssage'>
                                                                        <div className='pmsg'>
                                                                            <div className='inner'>
                                                                                <p>You have successfully concluded this escrow session.</p>
                                                                            </div>
                                                                            <small>
                                                                                <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                            </small>
                                                                        </div>
                                                                        <p className='sessionStatusTextBottom'>Escrow ended</p>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            mesg?.message_type === 'decline' ?
                                                                                // iD?.id === mesg[0] ?
                                                                                //     !msgr2 ?
                                                                                <div className='pmesssage'>
                                                                                    <div className='pmsg'>
                                                                                        <div className='inner'>
                                                                                            <p>You have declined the escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} sent by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                                                        </div>
                                                                                        <small>
                                                                                            <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                                        </small>
                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                mesg?.message_type === 'insuf' ?
                                                                                    <div className='pmesssage'>
                                                                                        <div className='pmsg'>
                                                                                            <div className='inner'>
                                                                                                <p>The escrow attempt was unsuccessful as the wallet ({mesg?.game_type === 'BTC' ? frnd?.btc : frnd?.eth})provided by <span className="bubbleUsername">{frnd?.username}</span> did not confirm the presence of the {num.format(mesg?.game_number)} {mesg?.game_type} escrow amount.</p>
                                                                                            </div>
                                                                                            <small>
                                                                                                <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                    : mesg?.message_type === 'cancel' ?
                                                                                        <div className='pmesssage'>
                                                                                            <div className='pmsg'>
                                                                                                <div className='inner'>
                                                                                                    <p>You have canceled the escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} that you sent to <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                                                                </div>
                                                                                                <small>
                                                                                                    <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                                                </small>
                                                                                            </div>

                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                        }
                                                                    </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </>
                        }
                    </>
                    :
                    <>
                        {mesg?.message_type === 'invite' || mesg?.message_type === 'invited' ?
                            <div className='messsage'>
                                <div className='msg'>
                                    <div className='inner'>
                                        <p><span className="bubbleUsername">{frnd?.username}</span> sent you an escrow request of {num.format(mesg?.game_number)} {mesg?.game_type}.</p>
                                    </div>

                                    <div style={mesg?.message_type === 'invited' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>
                                        <button onClick={decline} className='dec' >Decline</button>

                                        <button onClick={accept} className='acc' >Accept</button>
                                    </div>
                                    <small>
                                        <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                    </small>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    mesg?.message_type === 'accept' || mesg?.message_type === 'accepted' ?
                                        // iD?.id === mesg[0] ?
                                        //     !msgr4 ?
                                        <>
                                            <div className='messsage'>
                                                <p className='sessionStatusTextTop'>Escrow started</p>
                                                <div className='msg bbmsg'>
                                                    <div className='inner'>
                                                        <p><span className="bubbleUsername">{frnd?.username}</span> accepted your escrow request of {num.format(mesg?.game_number)} {mesg?.game_type}.</p>
                                                    </div>

                                                    <small>
                                                        <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                    </small>
                                                </div>
                                            </div>

                                            <div className='messsage'>
                                                <div className='msg bbmsg'>
                                                    <div className='inner'>
                                                        <p>We have confirmed the presence of the escrow amount of {num.format(mesg?.game_number)} {mesg?.game_type} in your provided wallet ({mesg?.game_type === 'BTC' ? user?.user?.btc : user?.user?.eth}) for the escrow transaction.</p>
                                                    </div>

                                                    <div style={mesg?.message_type === 'accepted' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>
                                                        <button onClick={endbtn2} className='dec' >End</button>
                                                    </div>
                                                    <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                mesg?.message_type === 'null' || mesg?.message_type === 'accepteed' ?
                                                    <div className='messsage'>
                                                        <div className='msg bbmsg'>
                                                            <div className='inner'>
                                                                <p>We have confirmed the presence of the escrow amount of {num.format(mesg?.game_number)} {mesg?.game_type} in your provided wallet ({mesg?.game_type === 'BTC' ? user?.user?.btc : user?.user?.eth}) for the escrow transaction.</p>
                                                            </div>

                                                            <div style={mesg?.message_type === 'accepteed' ? { display: 'none' } : { display: 'flex' }} className='msgbtn'>

                                                                <button onClick={endbtn} className='dec' >End</button>

                                                            </div>
                                                            <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            mesg?.message_type === 'end' ?
                                                                <div className='messsage'>
                                                                    <div className='msg bbmsg'>
                                                                        <div className='inner'>
                                                                            <p>This escrow session has been officially brought to a close by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                                        </div>
                                                                        <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                                    </div>
                                                                    <p className='sessionStatusTextBottom'>Escrow ended</p>
                                                                </div>
                                                                :
                                                                mesg?.message_type === 'ended' ?
                                                                    <div className='messsage'>
                                                                        <div className='msg bbmsg'>
                                                                            <div className='inner'>
                                                                                <p>This escrow session has been officially brought to a close by <span className="bubbleUsername">{frnd?.username}</span>.</p>
                                                                            </div>
                                                                            <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                                        </div>
                                                                        <p className='sessionStatusTextBottom'>Escrow ended</p>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            mesg?.message_type === 'decline' ?
                                                                                <div className='messsage'>
                                                                                    <div className='msg bbmsg'>
                                                                                        <div className='inner'>
                                                                                            <p><span className="bubbleUsername">{frnd?.username}</span> has declined the escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} you initiated.</p>
                                                                                        </div>
                                                                                        <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                                                    </div>
                                                                                </div>
                                                                                : mesg?.message_type === 'insuf' ?
                                                                                    <div className='messsage'>
                                                                                        <div className='msg bbmsg'>
                                                                                            <div className='inner'>
                                                                                                <p>The escrow attempt was unsuccessful as the wallet ({mesg?.game_type === 'BTC' ? user?.user?.btc : user?.user?.eth}) you provided did not confirm the presence of the {num.format(mesg?.game_number)} {mesg?.game_type} escrow amount.</p>
                                                                                            </div>
                                                                                            <small>
                                                                                                <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                    : mesg?.message_type === 'cancel' ?
                                                                                        <div className='messsage'>
                                                                                            <div className='msg bbmsg'>
                                                                                                <div className='inner'>
                                                                                                    <p>The escrow request of {num.format(mesg?.game_number)} {mesg?.game_type} was initiated by <span className="bubbleUsername">{frnd?.username}</span>, and has been canceled by the same party.</p>
                                                                                                </div>
                                                                                                <small><ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' /></small>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                        }
                                                                    </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </>
                        }
                    </>

            }
        </div>
    )
}

export default Messages