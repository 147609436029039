import { ArrowBack, PersonSearch, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import Newsrchbar from './Newsrchbar'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
import lott from '../asset/Animation - 1710972521237.json'
import Cookies from 'js-cookie';
import axios from 'axios'


function Mobsearch({ setflicker }) {

    axios.defaults.withCredentials = true

    const [srchd, setsrchd] = useState([])
    const [srch, setsrch] = useState('')



    const [data, setData] = useState([]);
    const [frndarr, setfrndarr] = useState([]);

    const [sts, setsts] = useState()



    useEffect(() => {

        const fetchData = async () => {
            const value = {
                key: process.env.REACT_APP_API_KEY2
            }
            try {
                await axios.post('https://wikiescrow.org/escrow/includes/search.inc.php', value).then((response) => {
                    if (response.data && response.status === 200) {
                        const filter = response?.data.filter((val) => {
                            return val.user_name.toLowerCase().includes(srch.toLowerCase())
                        })

                        setData(response.data);
                        setsrchd(filter)
                        setsts(true)
                    } else {
                        setsts(true)
                    }
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.post('https://wikiescrow.org/escrow/includes/retrieveFriends.inc.php').then((response) => {
                    if (response.status === 200) {
                        setfrndarr(response.data)
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);
    }, [])






    const searchnu = (e) => {
        const scrhword = e.target.value
        setsrch(scrhword)
        if (data) {
            const filter = data.filter((val) => {
                return val.user_name.toLowerCase().includes(scrhword.toLowerCase())
            })

            setsrchd(filter)
        }
    }

    const [bord, setbord] = useState(false)

    const srcclick = () => {
        setbord(true)
    }


    const [bar, setbar] = useState(false)

    const sidebarscroll = (e) => {
        setbar(e.target.scrollTop > 120)
    }


    const theme2 = async () => {
        //console.log('yes');
        const color = Cookies.get('theme');
        const element = document.getElementById('light');

        if (color === 'dark') {
            element.classList.remove('dark');
            element.classList.add('light');
            Cookies.set('theme', 'light', { domain: '.wikiescrow.org', path: '/' });
        }
        else if (color === 'light') {
            element.classList.remove('liht');
            element.classList.add('dark');
            Cookies.set('theme', 'dark', { domain: '.wikiescrow.org', path: '/' });
        } else {
            element.classList.remove('liht');
            element.classList.add('dark');
            Cookies.set('theme', 'dark', { domain: '.wikiescrow.org', path: '/' });
        }
    }

    const get = localStorage.getItem('theme')

    return (
        <div className='searhBoardContainer searhBoardMob'>
            <div className="app-header">
                {
                    bar ?
                        <Link to='/' onClick={() => setflicker(true)} className='linkbkss' >
                            <ArrowBack />
                        </Link>
                        :

                        <div className="app-header-left">
                            <a href="https://wikiescrow.org" className="app-icon press">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 792 792">
                                    <path
                                        d="m709.6 403.3-18 19c-42.2 45.3-102.7 66.2-160.1 55.3-18.8-3.5-62.6-14.6-108-27.2-38-10.6-51.1-13.2-52-10.5-1.5 5-.8 98.4.9 113.6C387.2 682 478.5 755.2 593 729.9c79-17.4 127.9-81.2 134-175.1 1.9-27.9 2-27.8-16.4-8.3-39 41.2-77.3 58-139.6 61.5-25.4 1.4-36-1-15.8-3.7C642.4 593.1 712 511 717.8 412c1-17.8.8-18-8.2-8.7"
                                        fill="#06a1db" fillRule="evenodd" />
                                    <path
                                        d="M241 379c-81.3 8-144.9 53.2-169.2 120.5C29 617.7 105.8 724.7 241 735c25.6 2 27 .5 11.8-12.7a185 185 0 0 1-62-110.1c-2-11-3.8-52.2-2.4-55.7 1.1-3 1.9-.9 3.2 9.6 11 85 92.5 152.7 191.9 159.4 17.3 1.1 18.3-.5 6-10.5-42.6-34.4-66.2-75.4-73-126.9-4.2-30.8-2-45.4 16.7-113.6 14.3-52.4 22.8-86.7 22.8-92.1V378l-53.7.1c-29.6.1-57.2.5-61.3 1"
                                        fill="#4b4392" fillRule="evenodd" />
                                    <path
                                        d="M218 69.4C121 80.5 62.6 149.8 60.8 256c-.3 18 0 18.5 6.9 11 46.5-51.7 103.4-77.3 160.5-72.4 12.3 1 16.7 3.5 6.2 3.5-17 0-50.2 12.8-73.9 28.5-54.5 36-83.1 87.3-90.2 161.8-2.2 24-1.2 24.8 11.4 9 43.9-54.8 93.8-78 158.5-73.5 20.6 1.5 21.5 1.7 87.3 19.2 86.7 23.1 84.8 22.7 87.6 19.3 2.4-2.8 2-102.3-.4-120.8-14.3-110.1-98-183.4-196.7-172"
                                        fill="#e50012" fillRule="evenodd" />
                                    <path
                                        d="M529.3 67.8c-7.4.4-7 2.6 1.4 9.9 41.6 36 63 77 68.3 130.3 2 20.5.2 46.2-2.2 32-11-63.1-46.9-110.5-106.4-140.5C457 82.8 385.9 69.7 389.4 81q.7 2 1.6 2.1a269 269 0 0 1 27.8 22.3q50 48.6 53.9 123.2c1.2 25-2 42.3-23.3 121.5-19 71.3-18.9 70.4-18.1 72.3 2 5.4 120.6 3 147.7-3C802.6 369.9 757.9 54 529.3 67.9"
                                        fill="#fdbf03" fillRule="evenodd" />
                                </svg>
                            </a>

                            <a className="app-backlink press" href="https://wikiescrow.org/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474 474" version="1.1">
                                    <path
                                        d="M 118.294 118.525 L 0.087 236.880 118.710 355.489 L 237.332 474.098 258.159 453.299 L 278.985 432.500 196.245 349.750 L 113.505 267 293.703 267 L 473.902 267 474.201 251.750 C 474.365 243.363, 474.364 229.862, 474.199 221.750 L 473.897 207 293.701 207 L 113.505 207 196.286 124.214 L 279.067 41.428 258.672 21.243 C 247.454 10.141, 238.514 0.819, 238.805 0.529 C 239.095 0.238, 238.696 0.038, 237.917 0.085 C 237.137 0.132, 183.307 53.430, 118.294 118.525"
                                        stroke="none" fillRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                }

                {
                    bar ?
                        <div id="app-pagetitle">Add contact</div>
                        :
                        null
                }


                <div className="app-header-right">
                    <div className="topthemeswitch" >
                        <button onClick={theme2} type="button" id="theme-toggle" className="mode-switch">
                            <svg className="themesun" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 3v1m0 16v1m-8-9H3m3.3-5.7-.8-.8m12.2.8.8-.8M6.3 17.7l-.8.8m12.2-.8.8.8M21 12h-1m-4 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <svg className="thememoon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13 6V3m5.5 9V7m-4-2.5h-3m9.5 5h-5m-.4 7.3q1.8 0 3.4-.7A8.4 8.4 0 1 1 8 5q-.8 1.6-.8 3.4c0 4.7 3.7 8.4 8.4 8.4"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className='desktoppsrch mobsrchbod' onScroll={sidebarscroll}>

                <div className='desksidebar desksidebarcontainer desktoppsrchbod'>
                    {
                        bar ?
                            <div className='stickedbar'>
                                <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                    <Search id='srchh' />
                                    <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Search' />
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className='desksidebarheader'>
                        <div className='mosrchinput'>
                            <div className='deskSidebarBackButton'>
                                <Link to='/' onClick={() => setflicker(true)} style={{ color: 'black' }} >
                                    <ArrowBack />
                                    <span>Back</span>
                                </Link>
                            </div>
                            <div className='sarrback sarrbackContainer'>
                                <h3>Search</h3>
                            </div>
                            <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                <Search id='srchh' />
                                <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Enter username' />
                            </div>
                        </div>
                    </div>



                    <div className='mosidebarchats' >
                        {
                            srch !== '' ?
                                sts ?
                                    <>
                                        {!srchd?.includes() ?
                                            srchd?.map((data) => (
                                                <Newsrchbar frndarr={frndarr} key={data.serial} setsrch={setsrch} data={data} />
                                            ))
                                            :
                                            <div>
                                                <p>yes</p>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className='popdicwallpaper popdicwallpaperSearch'>
                                        <Lottie className='ldd' animationData={lott} />
                                    </div>
                                :
                                <div className='emptcont'>
                                    <PersonSearch id='gmc' />
                                    <p>Search for users</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobsearch