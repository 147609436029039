import React, { useEffect, useState } from 'react'
import './mochat.css'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import Mobhome from './Mobhome'
import Mobsearch from './Mobsearch'
import { useDispatch } from 'react-redux'
import { info } from '../features/infoslice'
import Loader from '../Loader'
import Preloader from '../Deskchat.js/Preloader'
import axios from 'axios'


function Mobchat({ data }) {

  axios.defaults.withCredentials = true


  const dispatch = useDispatch()



  useEffect(() => {
    const func = async () => {
      try {
        const arr = {
          email: `${data?.email}`,
          name: `${data?.name}`,
          image: `${data?.image}`,
          notificati: `${data?.notification}`,
          balance: `${data?.balance}`,
          usertype: `${data?.userType}`,
          btc: `${data?.btcAddress}`,
          eth: `${data?.ethAddress}`,
          username: `${data?.username}`,
          num: 0
        }

        dispatch(
          info({
            user: arr,
          })
        )
      } catch (e) {

      }
    }


    let int = setInterval(() => {
      func()
    }, 1000);

    return () => {
      clearInterval(int)
    }
  }, [])

  // dispatching user info ends 




  useEffect(() => {
    const func = async () => {
      try {
        await axios.post('https://wikiescrow.org/escrow/includes/updateOnlineStatus.inc.php').then((response) => {
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const interval = setInterval(func, 10000);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [])



  const [flicker, setflicker] = useState(null)




  return (
    <>
      {
        document.readyState === 'complete' ?
          <Router>
            <Routes>
              <Route path='/' element={<Mobhome flicker={flicker} />} />
              <Route path='/profile' element={<Preloader />} />
              {/* <Route path='/adduser' element={<Moadd />} />  */}
              <Route path='/adduser' element={<Mobsearch setflicker={setflicker} />} />
            </Routes>
          </Router>
          :
          <Loader />
      }
    </>
  )
}

export default Mobchat