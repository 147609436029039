import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import axios from 'axios'


function Mobbars({ frnds, setflip }) {


  axios.defaults.withCredentials = true

  const chat = async () => {
    const arr = {
      image: frnds?.image,
      combined_id: frnds?.combined_id,
      name: frnds?.name,
      email: frnds?.user_email,
      username: frnds?.username,
      eth: frnds?.eth_address,
      btc: frnds?.btc_address,
      notification: frnds?.notification,
      usertype: frnds?.usertype,
    }

    var jsonString = JSON.stringify(arr);

    localStorage.setItem("myObject", jsonString);

    const value = {
      combined_id: frnds?.combined_id
    }

    try {
      await axios.post('https://wikiescrow.org/escrow/includes/clearMessageAlert.inc.php', value).then((response) => {
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setflip(false)

  }


  const timeInMillis = frnds?.timestamp * 1000;






  return (
    <div onClick={chat} key={frnds?.timestamp} className='sidebars'>
      <div className='avatarbod'>
        <div className={`${frnds?.image}`}></div>
      </div>
      {/* <div className='userStatusWrapper'>{timer ? <div className='userOnlineStatus'></div> : <div className='userOfflineStatus'></div>}</div> */}

      <div className='sidebarwrtHouse'>
        <small className='sidebarwrtSmall'>
          <>
            {frnds?.timestamp ?
              <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
              :
              null
            }
          </>
        </small>

        <div className='sidebarwrtContainer'>
          <div className='sidebarwrt' >
            <h3>{frnds?.name}</h3>
            <p>@{frnds?.username}</p>
          </div>

          {
            frnds?.notification > 0 ?
              <div className='sideBarMailNoticeContainer'>
                <div className='sideBarMailNotice'>{frnds?.notification}</div>
              </div>
              :
              null
          }


        </div>
      </div>
    </div>
  )
}

export default Mobbars