import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import frndReducer from '../features/frndSlice';
import notifyReducer from '../features/notifySlice';
import onlineReducer from '../features/onlineSlice';
import notReducer from '../features/notSlice';
import infoReducer from '../features/infoslice';
import idReducer from '../features/idSlice';

export const store = configureStore({
  reducer: {
    iD: idReducer,
    info: infoReducer,
    not: notReducer,
    online: onlineReducer,
    notify: notifyReducer,
    frnd: frndReducer,
    user: userReducer,
  },
});
