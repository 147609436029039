import React, { useEffect, useState } from 'react';
import './App.css';
import Mobchat from './Mochat.js/Mobchat';
import Desk from './Deskchat.js/Desk';
import Loader from './Loader';
import Redloader from './Redloader';
import axios from 'axios';
import Cookies from 'js-cookie';


function App() {


  // checking auth ends







  axios.defaults.withCredentials = true



  // checking auth starts //

  const [data, setData] = useState(null);
  const [dataemail, setDataemail] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        await axios.post('https://wikiescrow.org/escrow/logStatus/userState.php').then(async (response) => {
          if (response.status === 200) {
            setData(response.data);
            setDataemail(response.data.email);

          } else {
          }
        });


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);


  useEffect(() => {
    const func = async () => {
      await axios.post('https://wikiescrow.org/escrow/notification/clear.php').then((response) => {
      });
    }

    func()
  }, [])


  useEffect(() => {

    const fetchData = async () => {
      try {
        await axios.post('https://wikiescrow.org/escrow/logStatus/extraTime.php').then((response) => {
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      let fnc = setInterval(() => {
        fetchData();
      }, 300000);

      setTimeout(() => {
        clearInterval(fnc);
      }, 600000);
    }

  }, []);



  // theme function starts //

  const col = () => {
    const element = document.getElementById('light');
    const color = Cookies.get('theme');

    if (color === 'dark') {
      element.classList.add('dark');
    } else if (color === 'light') {
      element.classList.remove('dark');
    }

  }

  useEffect(() => {
    col()
  }, [])












  return (
    <>
      {data ?
        data?.userStatus === 'online' || dataemail ?

          <div>
            <Desk data={data} />

            <Mobchat data={data} />
          </div>

          :
          <Redloader />

        :
        <Loader />
      }
    </>
  );
}

export default App;
