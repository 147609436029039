import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { frnd } from '../features/frndSlice'
import ReactTimeAgo from 'react-time-ago'
import { selectinfo } from '../features/infoslice'
import axios from 'axios'

function Bars({ frnds, setmsgrl, setmsgr2, setmsgr3, setmsgr4, setmsgr5, setpop, fetchData, fetchData1 }) {


  axios.defaults.withCredentials = true
  const user = useSelector(selectinfo)


  const dispatch = useDispatch()

  const chat = async () => {
    fetchData()
    fetchData1()
    const value = {
      combined_id: frnds?.combined_id
    }

    try {
      await axios.post('https://wikiescrow.org/escrow/includes/clearMessageAlert.inc.php', value).then((response) => {
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    const arr = {
      image: frnds?.image,
      combined_id: frnds?.combined_id,
      name: frnds?.name,
      email: frnds?.user_email,
      username: frnds?.username,
      eth: frnds?.eth_address,
      btc: frnds?.btc_address,
      notification: frnds?.notification,
      usertype: frnds?.usertype,
    }

    dispatch(
      frnd({
        id: arr,
      })
    )

    var jsonString = JSON.stringify(arr);

    localStorage.setItem("myObject", jsonString);

    setmsgrl(false)
    setmsgr2(false)
    setmsgr3(false)
    setmsgr4(false)
    setpop(true)
    setmsgr5(false)

  }





  const timeInMillis = frnds?.timestamp * 1000;








  return (
    <div onClick={chat} className={user?.user.username === frnds?.username ? 'sidebars desk-sidebars activeSidebars' : 'sidebars desk-sidebars'}>
      <div className='avatarbod'>
        <div className={`${frnds?.image}`}></div>
      </div>

      {/* <div className='userStatusWrapper'>{timer ? <div className='userOnlineStatus'></div> : <div className='userOfflineStatus'></div>}</div> */}

      <div className='sidebarwrtHouse'>
        <small className='sidebarwrtSmall'>
          <>
            {frnds?.timestamp ?
              <ReactTimeAgo date={new Date(timeInMillis)} locale='en-US' />
              :
              null
            }
          </>
        </small>

        <div className='sidebarwrtContainer'>
          <div className='sidebarwrt'>
            <h3>{frnds?.name}</h3>
            <p>@{frnds?.username}</p>
          </div>

          {
            frnds?.notification > 0 ?
              <div className='sideBarMailNoticeContainer'>
                <div className='sideBarMailNotice'>{frnds?.notification}</div>
              </div>
              :
              null
          }

        </div>
      </div>
    </div>

  )
}

export default Bars