import { createSlice } from '@reduxjs/toolkit';



export const notSlice = createSlice({
    name: 'notify',
    initialState: {
        not: null,
    },
    reducers: {
        not: (state, action) => {
            state.not = action.payload;
        },
    }
});

export const { not } = notSlice.actions;

export const selectnot = (state) => state.not.not;

export default notSlice.reducer;
