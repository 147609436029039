import { createSlice } from '@reduxjs/toolkit';



export const iDSlice = createSlice({
    name: 'iD',
    initialState: {
        iD: null,
    },
    reducers: {
        iDs: (state, action) => {
            state.iD = action.payload;
        },
    }
});

export const { iDs } = iDSlice.actions;

export const selectid = (state) => state.iD.iD;

export default iDSlice.reducer;
